<template>
  <v-container v-bind="propsPageMainContainer" data-cy="users-index-page">
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("users.indexTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-if="authCan('create.users')"
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'users-create' }"
              data-cy="button-create"
            >
              <v-icon>$pageCreate</v-icon>
            </v-btn>
          </div>
          <UsersIndex></UsersIndex>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import UsersIndex from "../components/UsersIndex.vue";

export default {
  name: "UsersIndexPage",
  mixins: [Permissions],
  components: {
    UsersIndex,
  },
};
</script>
