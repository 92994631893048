<template>
  <v-skeleton-loader :loading="status.reading" type="article@3">
    <v-sheet v-bind="propsCompSheet" data-cy="users-index">
      <v-sheet
        v-bind="propsSheetSearchSpace"
        v-if="stateIndex === 'loading'"
        class="mb-4"
      >
        {{ $t("common.loading") }}
      </v-sheet>
      <v-sheet
        v-bind="propsSheetSearchSpace"
        v-if="stateIndex === 'noResults'"
        class="mb-4"
      >
        {{ $t("common.searchNoResults") }}
      </v-sheet>
      <div v-if="stateIndex === 'results'" class="mb-4">
        <v-list two-line>
          <v-list-item
            v-for="item in users"
            :key="item.id"
            :to="{ name: 'users-show', params: { id: item.id } }"
          >
            <v-list-item-avatar color="grey">
              <v-img :src="item.avatarUrl ? item.avatarUrl : ''"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="d-flex align-center"
                ><span>{{ item.name }} {{ item.surnames }}</span>
                <UserRoleChip :roleValue="item.role" class="ml-2"></UserRoleChip
              ></v-list-item-title>
              <v-list-item-subtitle>
                {{ item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="authCan('create.users')"
          v-bind="propsButtonAction"
          :to="{ name: 'users-create' }"
          data-cy="button-create"
          >{{ $t("common.new") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import UserRoleChip from "@/modules/users/bits/UserRoleChip";
import { mapGetters } from "vuex";

export default {
  name: "UsersIndex",
  mixins: [Permissions, ComponentStatus],
  components: {
    UserRoleChip,
  },
  computed: {
    stateIndex() {
      let state = "noSearch";

      if (this.status.loading) {
        state = "loading";
      } else if (this.users.length === 0) {
        state = "noResults";
      } else {
        state = "results";
      }
      return state;
    },
    ...mapGetters({
      users: "users/readAll",
    }),
  },
  created() {
    this.setStatusReading();
    this.$store
      .dispatch("users/readAll")
      .then((/* result */) => {
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
        this.$store.commit("status/showError");
      });
  },
};
</script>
